import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f3f3f3',
    // backgroundColor: theme.palette.background.default,
    // overflowX: 'hidden',
    overflowX: 'hidden',
    // flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: `calc(${theme.custom.headerHeight}px)`,
    bottom: '0',
    height: 'auto',
    // border: 'none',
    // zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      bottom: `calc(${theme.custom.headerHeight}px - 10px)`,
    },
    '&.opened': {
      width: theme.custom.sideNavWidthOpened,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '&.closed': {
      width: theme.custom.sideNavWidthClosed,
      // transition: theme.transitions.create('width', {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
    '&.resMenu': {
      width: theme.custom.sideNavWidthOpened,
      zIndex: 1,
    },
  },
  wrapper: {
    overflowX: 'hidden',
  },
  listItem: {
    // color: theme.palette.secondary.dark,
    color: theme.palette.primary.shades[10],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.selected': {
      backgroundColor: theme.palette.primary.shades[12],
      borderRadius: 24,
      '& $itemIcon': {
        color: theme.palette.primary.shades[10],
      },
      // borderRight: `3px solid ${theme.palette.secondary.light}`,
    },
    '& +$listItem': {
      marginTop: 8,
    },
    '&.stuckToBottom': {
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
    },
    '&:nth-child(3)': {
      marginBottom: 30,
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: -10,
        height: 1,
        background: '#ccc',
        width: '100%',
      },
    },
  },
  itemIcon: {
    color: 'rgb(0 0 0 /.7)',
    // color: theme.palette.secondary.dark,
    padding: theme.spacing(0, 2),
    '& .MuiIconButton-root': {
      padding: '8px 8px',
      '&:hover': {
        // backgroundColor: theme.palette.secondary.shades[3],
        color: theme.palette.primary.shades[10],
        background: theme.palette.common.white,
      },
    },
  },
  itemTxt: {
    textTransform: 'capitalize',
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    margin: theme.spacing(0, 2),
    textTransform: 'capitalize',
    '&.hidden': {
      display: 'none',
    },
  },
  menuOverlay: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    background: 'rgba(0,0,0,0.7)',
    display: 'none',
  },
  menuOverlayOpen: {
    display: 'block',
  },
  link: {
    textDecoration: 'none',
  },

}));
