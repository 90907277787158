import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  navbar: {
    height: "65px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
    paddingInline: "24px",
    marginBottom: "30px",
  },
  leftColumn: {
    //minWidth: "215px",
    display: "flex",
    flexDirection: "column",
    width: "75px",
    backgroundColor: "#f3f3f3",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    height: "100vh",
    alignItems: "center",
    justifyContent: "space-between",
  },

  selectedTab: {
    backgroundColor: "#E8F0FE",
    color: "#1034a6",
    padding: "8px 8px",
    display: "flex",
    alignItems: "center",
    //borderRight: "3.5px solid",
    marginBottom: "12px",
    borderRadius: "50%",
  },

  tab: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px",
    marginBottom: "12px",
    cursor: "pointer",
    "&:hover": {
      color: "#1034a6",
      backgroundColor: "#fff",
    },
    borderRadius: "850%",
  },

  rightColumn: {
    padding: "40px 40px 10px 40px",
    height: "calc(100vh - 151px)",
    //overflow: "auto",
    width: "100%",
  },

  title: {
    color: "#282828",
    fontSize: "24px",
  },
  createBtn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      textTransform: "capitalize",
      marginLeft: 20,
      paddingInline: "12px",
      fontSize: "15px",
      fontWeight: "500",
      borderRadius: "8px",
      height: "32px",
    },
  },

  searchInput: {
    height: "34px",
    width: "200px",
    borderRadius: "6px",
  },
  mt1: {
    "&.MuiTypography-root": {
      fontWeight: "500 ",
      color: "rgba(0,0,0,.87)",
      marginTop: 8,
      fontSize: "1.1rem",
    },
  },
  mt3: {
    "&.MuiTypography-root": {
      fontWeight: "500 ",
      color: "rgba(0,0,0,.87)",
      marginTop: 24,
      fontSize: "1.5rem",
    },
  },

  tabTitle: {
    fontSIze: "17px",
    lineHeight: "22px",
    marginLeft: "14px",
    color: "inherit",
  },
  scrollable: {
    // maxHeight: 'calc(100vh - 233px)',
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[700],
      borderRadius: 20,
    },
  },
  tooltip: {
    "&.MuiTooltip-tooltip": {
      backgroundColor: "#1034a6",
    },
  },
}));
