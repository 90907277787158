import { Box, Typography, TextField, Button, useMediaQuery, Tooltip } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useStyles } from './styles';
//import Logo from '../../shared/assets/IconCervelloAppsAlfa.svg';
import WidgetsIcon from '@material-ui/icons/Widgets';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import Application, { App } from './App'
import AddIcon from '@material-ui/icons/Add';
import AllApplications from './Tabs/AllApps'
import SharedApplications from './Tabs/SharedWIthMe'
import cervelloLogo from 'shared/assets/suite-logo.svg'
import HeaderIcons from '../../../Layout/Header/HeaderIcons'


export default function Apps() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const tabs = [
    { title: 'My Applications', name: 'Applications', icon: <WidgetsIcon style={{ fontSize: "26px" }} />, component: <AllApplications /> },
    // { title: 'Starred Applications', name: 'Starred', icon: <StarIcon style={{fontSize:"26px"}} />, component: <StarredApplications /> },
    { title: 'Shared with me', name: 'Shared with me', icon: <FolderSharedIcon style={{ fontSize: "26px" }} />, component: <SharedApplications /> },
  ]



  return (


    <div style={{ overflow: "hidden", height: "calc(100vh - 5px)" }}>

      <Box display='flex'  >

        <Box className={classes.leftColumn} >
          <Box>
            <div style={{ display: 'flex', justifyContent: 'center', margin: "14px 0px  " }} >
              <img alt='suite-logo' src={cervelloLogo} width={35} />
            </div>
            {tabs.map((tab, i) => (
              <Tooltip placement='right' classes={{ tooltip: classes.tooltip }} key={tab.name} title={
                tab.name
              } >
                <Box onClick={() => setSelectedTab(i)} className={tabs[selectedTab].name === tab.name ? classes.selectedTab : classes.tab} >
                  {tab.icon}

                </Box>
              </Tooltip>
            ))}
          </Box>
          <HeaderIcons />
        </Box>



        {tabs[selectedTab].component}


      </Box>
    </div>

  )
}
